.bold {
    font-weight: 800;
    color: red;
}

.width td {
    width: 380px !important;
}

.push {
    display: flex;
    gap: 40px;
}

.pushOne {
    display: flex;
}

.pushOne label {
    width: 900px;
}
.wrapMarking {
    display: flex;
    gap: 60px;
    border-bottom: 1px solid #f0f3f5;
}

.formgroup input {
    margin-bottom: 40px;
    width: 400px;
}

.formGoupOne {
    width: 400px;
}

.errorInput > div {
    border-color: red;
}
.text-red {
    color: red;
}
